import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
import Authorise from "@/views/authorise";
import Layout from "@/layout/Layout.vue";
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/authorise",
    component: Authorise,
    meta: {
      title: "授权登录",
    },
  },
  // 首页
  {
    path: "/home",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/home"),
      },
    ],
    meta: {
      title: "首页",
    },
  },
  // 个人中心
  {
    path: "/person",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/person"),
      },
    ],
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/today_register",
    component: () => import("@/views/today_register"),
    meta: {
      title: "当日挂号",
    },
  },
  {
    path: "/arrange_register",
    component: () => import("@/views/arrange_register"),
    meta: {
      title: "预约挂号",
    },
  },
  {
    path: "/arrange_work",
    component: () => import("@/views/arrange_work"),
    meta: {
      title: "医生排班",
    },
  },
  {
    path: "/register_select",
    component: () => import("@/views/register_select"),
    meta: {
      title: "挂号选择",
    },
  },
  {
    path: "/register_info",
    component: () => import("@/views/register_info"),
    meta: {
      title: "挂号信息",
    },
  },
  {
    path: "/cashier_desk",
    component: () => import("@/views/cashier_desk"),
    meta: {
      title: "缴费窗口",
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
