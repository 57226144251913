<template>
  <div class="layout">
    <!-- 中间内容 -->
    <AppMain />
    <div class="tabbar">
      <!-- 底部导航 -->
      <Tabbar />
    </div>
  </div>
</template>

<script>
//引入组件
import AppMain from "./AppMain.vue";
import Tabbar from "./Tabbar.vue";

export default {
  data() {
    return {};
  },
  components: {
    AppMain,
    Tabbar,
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.layout {
  .tabbar {
    position: fixed;
    bottom: 0;
  }
}
</style>
