<template>
  <div>
    <van-tabbar v-model="active">
      <van-tabbar-item name="/home" icon="home-o" @click="switchTab('/home')"
        >首页</van-tabbar-item
      >
      <van-tabbar-item
        name="/person"
        icon="search"
        @click="switchTab('/person')"
        >个人中心</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "/home",
    };
  },
  methods: {
    switchTab(name) {
      this.$router.push(name);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.van-tabbar {
  padding-bottom: 40px;
}
</style>
