<template>
  <div class="appmain" v-wechat-title="$route.matched[0].meta.title">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {
    console.log("this.$route", this.$route);
  },
};
</script>

<style lang="scss" scoped>
.appmain {
  padding-bottom: 150px;
  /* 隐藏滚动条 */
}
</style>
