import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/font/iconfont.css";
import "@/assets/font/iconfont";
import {
  Grid,
  GridItem,
  Button,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  Search,
  List,
  Cell,
  CellGroup,
  Tab,
  Tabs,
  Empty,
  Toast,
  Dialog,
  RadioGroup,
  Radio
} from "vant";
Vue.use(Grid)
  .use(GridItem)
  .use(Button)
  .use(Swipe)
  .use(SwipeItem)
  .use(Tabbar)
  .use(Search)
  .use(List)
  .use(Cell)
  .use(CellGroup)
  .use(Tab)
  .use(Tabs)
  .use(Toast)
  .use(Empty)
  .use(Dialog)
  .use(RadioGroup)
  .use(Radio)
  .use(TabbarItem);
Vue.config.productionTip = false;
// 引入样式
import "./assets/css/index.scss";
// 引入淘宝适配方案
import "lib-flexible";
// 顶部标题
import VueWechatTitle from "vue-wechat-title";

Vue.use(VueWechatTitle);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
