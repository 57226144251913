<template>
  <div class="app">
    <router-view #default="{ Component }">
      <keep-alive include="">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.app {
  width: 540px;
  margin: 0 auto;
  height: 100%;
}
</style>
