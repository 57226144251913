<template>
  <div v-wechat-title="$route.meta.title">
    <van-button type="primary" @click="authorise()">授权</van-button>
  </div>
</template>

<script>
export default {
  created() {
    // this.authorise();
  },
  methods: {
    authorise() {
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxff53217b74a20b8d&redirect_uri=http://192.168.1.233:8080/%23/home/&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect";
    },
  },
};
</script>
+

<style lang="scss" scoped></style>
